// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// only for this since we can't use import for some reason
/* eslint-disable @typescript-eslint/no-var-requires */
// tslint:disable-next-line:no-var-requires
const packageJson = require('../../../../package.json');

export const environment = {
  algolia: {
    algoliaSearchApiKey: 'db5341eabb628cf0855eb3d280d59286',
    algoliaAppId: 'IJVU6OWSHE',
    algoliaBuildingIndex: 'prod_cdk_building',
    algoliaPropertyIndex: 'prod_cdk_property',
    algoliaAreaIndex: 'prod_cdk_area'
  },
  appName: 'DashQ',
  envName: 'DEV',
  baseUrl: {
    webapp: 'https://dev-portal.dashq.io/',
    bpBaseUrl:
      'https://dashqdevblobstorage.blob.core.windows.net/dq-building-photos/',
    urBaseUrl:
      'https://dashqdevblobstorage.blob.core.windows.net/dq-user-recordings/',
    paBaseUrl:
      'https://dashqdevblobstorage.blob.core.windows.net/dq-rep-photos/',
    staticBaseUrl:
      'https://dashqdevblobstorage.blob.core.windows.net/dq-static/',
    voiceBaseUrl:
      'https://dashqdevblobstorage.blob.core.windows.net/dq-voice-messages/'
  },
  clarity: {
    projectId: 'gxvir4u6d0',
    enabled: false
  },
  datadog: {
    site: 'us3.datadoghq.com',
    clientToken: 'pub299f4b47490dd4adbb0d0d57a6a57e2f'
  },
  intercom: {
    appId: 'inpvko4n'
  },
  production: false,
  test: false,
  printActions: false,
  i18nPrefix: '',
  hubBaseUrl: 'https://dashq-dev-webapi.azurewebsites.net/hubs/',
  apiBaseUrl: 'https://dashq-dev-webapi.azurewebsites.net/api/',
  websiteUrl: 'https://dev-portal.dashq.io/',
  zoom: {
    zoomClientId: 'MkyF3KfTGqKGW_lGP434A'
  },
  microsoftGraph: {
    microsoftClientId: '07c6b8ed-8771-428b-80fe-db87c2c438da'
  },
  appInsights: {
    instrumentationKey: '3bac3760-b91e-4d65-bea8-146040865116'
  },
  tinyMce: {
    tinyMceApiKey: 'lxdy0yc38kf0gbnf8jhggzhxh1yozbpgrbnjtt55ry7p1cz4'
  },
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  }
};
